/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useRef } from "react";
import useTranslation from "next-translate/useTranslation";

const Roadmap = () => {
    const { t } = useTranslation("home"); // Assuming your translations are in home.json
    const sliderRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slidesPerView, setSlidesPerView] = useState(4);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [startX, setStartX] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    // Roadmap data referencing the translation keys
    const RoadmapData = [
        {
            id: 1,
            title: t("roadmap.title1"),
            date: t("roadmap.date1"),
            description: t("roadmap.desc1"),
        },
        {
            id: 2,
            title: t("roadmap.title2"),
            date: t("roadmap.date2"),
            description: t("roadmap.desc2"),
        },
        {
            id: 3,
            title: t("roadmap.title3"),
            date: t("roadmap.date3"),
            description: t("roadmap.desc3"),
        },
        {
            id: 4,
            title: t("roadmap.title4", { Multi: "Multi", Blockchain: "Blockchain", DEX: "DEX" }),
            date: t("roadmap.date4"),
            description: t("roadmap.desc4", { blockchains: "Blockchains", cross: "cross", Blockchain: "Blockchain", non: "Non", chain: "Chain", custodial: "Custodial" }),
        },
        {
            id: 5,
            title: t("roadmap.title5"),
            date: t("roadmap.date5"),
            description: t("roadmap.desc5"),
        },
        {
            id: 6,
            title: t("roadmap.title6"),
            date: t("roadmap.date6"),
            description: t("roadmap.desc6"),
        },
        {
            id: 7,
            title: t("roadmap.title7"),
            date: t("roadmap.date7"),
            description: t("roadmap.desc7", { blockchains: "Blockchains", cross: "cross", Blockchain: "Blockchain", non: "Non", chain: "Chain", custodial: "Custodial" }),
        }
    ];

    const breakpoints = {
        0: { slidesPerView: 1 },
        576: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        992: { slidesPerView: 3 },
        1200: { slidesPerView: 4 },
        1400: { slidesPerView: 4 }
    };

    const updateSlidesPerView = () => {
        const width = window.innerWidth;
        let newSlidesPerView = 4;

        // Sort breakpoints in ascending order
        const breakpointValues = Object.keys(breakpoints)
            .map(Number)
            .sort((a, b) => a - b);

        // Find the correct breakpoint for current width
        for (const breakpoint of breakpointValues) {
            if (width >= breakpoint) {
                newSlidesPerView = breakpoints[breakpoint].slidesPerView;
            }
        }

        setSlidesPerView(newSlidesPerView);
    };

    useEffect(() => {
        updateSlidesPerView();
        window.addEventListener("resize", updateSlidesPerView);
        return () => window.removeEventListener("resize", updateSlidesPerView);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isTransitioning && !isHovered) {
                nextSlide();
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [currentIndex, isTransitioning, isHovered]);

    const handleDragStart = (e) => {
        setStartX(e.clientX);
    };

    const handleDragEnd = (e) => {
        if (e.clientX < startX) {
            nextSlide();
        } else if (e.clientX > startX) {
            prevSlide();
        }
    };

    const nextSlide = () => {
        setIsTransitioning(true);
        setCurrentIndex((prev) => {
            const next = prev + 1;
            if (next >= RoadmapData.length - 3) {
                setTimeout(() => setIsTransitioning(false), 500);
                return 0; // Reset to the first slide
            }
            setTimeout(() => setIsTransitioning(false), 500);
            return next;
        });
    };

    const prevSlide = () => {
        setIsTransitioning(true);
        setCurrentIndex((prev) => {
            const newIndex = prev - 1;
            if (newIndex < 0) return prev; // Stop at the first slide
            setTimeout(() => setIsTransitioning(false), 500);
            return newIndex;
        });
    };

    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <section className="roadmap roadmap--style2 padding-top padding-bottom banner--style46">
            <div className="container">
                <div className="section-header section-header--max50">
                    <h2 className="mb-15 mt-minus-5">
                        <span className="style2">
                            {t("roadmap.sectionTitle")}
                        </span>
                    </h2>
                    <p>
                        {t("roadmap.sectionSubtitle", { Blockchains: "Blockchains", cross: "cross", Blockchain: "Blockchain", non: "Non", chain: "Chain", custodial: "Custodial" })}
                    </p>
                </div>

                <div className="roadmap__wrapper" style={{cursor:'pointer'}}>
                    <div className="roadmap__upper">
                        <div className="roadmap__upper-inner">
                            <div
                                className="roadmap__slider"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={{ overflow: "hidden", position: "relative" }}
                            >
                                <div
                                    ref={sliderRef}
                                    className="slides-track"
                                    onMouseDown={handleDragStart}
                                    onMouseUp={handleDragEnd}
                                    style={{
                                        display: "flex",
                                        transition: isTransitioning ? "transform 0.5s ease" : "none",
                                        transform: `translateX(-${(currentIndex * 100) / slidesPerView}%)`
                                    }}
                                >
                                    {RoadmapData.map((item, index) => (
                                        <div
                                            key={index}
                                            className="roadmap__slide"
                                            style={{
                                                minWidth: `${100 / slidesPerView}%`,
                                                padding: '10px'
                                            }}
                                        >
                                            <div
                                                className={
                                                    item.id % 2 === 0
                                                        ? "roadmap__item roadmap__item--reverse"
                                                        : "roadmap__item"
                                                }
                                            >
                                                <div
                                                    className={`roadmap__item-inner roadmap__item-inner--vertical-line-${item.id % 2 === 0 ? "top" : "bottom"
                                                        }`}
                                                >
                                                    <div className="roadmap__item-content">
                                                        <h3 style={{ fontSize: "1.2rem" }}>{item.title}</h3>
                                                        <p>{item.description}</p>
                                                    </div>
                                                    <div className="roadmap__item-date">
                                                        <span>{item.date}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>

                        </div>
                        <div className="roadmap__slider-indicators" style={{ textAlign: "center" }}>
                            {!isMobile
                                ? RoadmapData.slice(0, RoadmapData.length - 3).map((_, i) => (
                                    <span
                                        key={i}
                                        onClick={() => setCurrentIndex(i)}
                                        style={{
                                            margin: '0 4px',
                                            cursor: 'pointer',
                                            transform: i === currentIndex ? 'scale(1.2)' : 'scale(1)',
                                            transition: 'transform 0.2s ease',
                                            opacity: i === currentIndex ? 1 : 0.5,
                                            fontSize: "2rem",
                                        }}
                                    >
                                        _
                                    </span>
                                ))
                                : RoadmapData.map((_, i) => (
                                    <span
                                        key={i}
                                        onClick={() => setCurrentIndex(i)}
                                        style={{
                                            margin: '0 4px',
                                            cursor: 'pointer',
                                            transform: i === currentIndex ? 'scale(1.2)' : 'scale(1)',
                                            transition: 'transform 0.2s ease',
                                            opacity: i === currentIndex ? 1 : 0.5,
                                            fontSize: "2rem",
                                        }}
                                    >
                                        _
                                    </span>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Roadmap;
